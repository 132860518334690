import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthenticationService } from '../services/apiService';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { LoginResetComponent } from '../login.reset/login.reset.component';

@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit {
  qrCodeModify:String;
  qrCodeModifyeee:String;
  dataOtp:any={
    token:null,
    otp:null
  }
  loginResetData: any = {}
  loginForm: any = {}
  qrcodeSvg: SafeHtml
  constructor(
    public dialogRef: MatDialogRef<AuthenticatorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    public auth: AuthenticationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private sanitizer: DomSanitizer,
    private loader: NgxUiLoaderService,
    private toast : ToastrService,
    private nav: Router,
    public dialog: MatDialog,
  ) {
  }
  
  ngOnInit() {
    console.log("voir le this.param")
    console.log(this.param)
    this.qrcodeSvg = this.sanitizer.bypassSecurityTrustHtml(this.param.QrCode);
  }
  
  sendCodeOtp() {
    if(!this.dataOtp.codeOtp)
      {
      this.toast.error("Veuillez saisir un code OTP");
      return;
    }
    this.dataOtp={token:this.param.token,
      otp:this.dataOtp.codeOtp}
      this.loader.start()
      this.auth
      .validateOtp(this.dataOtp)
      .then((res: any) => {
        this.loader.stop();
        this.storage.set('admin-data', res.data.user)
        this.storage.set('admin-menu', res.data.profil_access)
        console.log(res);
        // if(res.data.user.is_first_connection==0 || res.data.user.is_first_connection=='0' || res.data.user.is_change_password_duration == 1)
        //   {
        //   this.storage.clear();
        //   console.log("Reset password catched:"+res.data.user.is_first_connection)
        //   this.loginResetData={
        //     email:this.loginForm.email,
        //     type:'admin'
        //   }
        //   this.openDialog(this.loginResetData);
        // }
        // else
        // {
          if(res.data.user.id_entity && res.data.user.type_entity_id==2)
            {
            console.log("yessssssssssssss station catched:"+res.data.user.id_entity)
            this.nav.navigate(['/admin/dashboard-gerant']);
          }
          else
          {
            console.log("no station catched:"+res.data.user.id_entity)
            this.nav.navigate(['/admin/accueil']);
          }
          this.dialogRef.close({ status: 1, message: res.message });
          this.toast.success(res.message);
        // }
      })
      .catch(res => {
        this.dialogRef.close({ status: 0, message: res.error.message });
        this.loader.stop();
        this.toast.error(res.error.message);
        console.log(res);
      });
    }
    
    openDialog(data): void{
      
      const dialogRef = this.dialog.open(LoginResetComponent, {
        width: '50%',
        disableClose: true ,
        hasBackdrop:false,
        data:data
        
      });
      
      dialogRef.afterClosed().subscribe(async (result:any) => {
        console.log('The dialog was closed', result);
        //if(result){
        this.nav.navigate(['/admin/login']);
        // }
      });
      
    }
    
    onKeydown(event) {
      if (event.key === "Enter") {
        console.log(event);
        this.sendCodeOtp();
      }
    }
    
  }

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from 'src/app/services/apiService';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
//import { ReclamationComponent } from '../reclamation/reclamation.component';
import { LoginComponent } from '../login.admin/login.component';
import { stringify } from 'querystring';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-login-reset',
  templateUrl: './login.reset.component.html',
  styleUrls: ['./login.reset.component.scss']
})
export class LoginResetComponent implements OnInit {


  loginResetData: any = {}

  constructor(@Inject(LOCAL_STORAGE)
   private storage: StorageService,
   public api: AuthenticationService,
   public dialogRef: MatDialogRef<LoginComponent>,
   public toast: ToastrService,
   public dialog: MatDialog,@Optional() @Inject(MAT_DIALOG_DATA)
   public param: any,
   private loader: NgxUiLoaderService,
   private nav: Router,
   )
   {
    dialogRef.disableClose = true;
/*      this.getTypeTransaction(),
     this.getStation(),
     this.getTPE() */
    }



  ngOnInit() {
    console.log("donné mis en parametre",this.param)
    if(this.param!= null){
      this.loginResetData.email = this.param.email
      console.log("donné apres avoir mis en string",this.param)
    }

  /*   this.user = this.storage.get('user'); */
  }

  closeModal(data){
    console.log(this.param.type)
    this.dialogRef.close(data);
    this.storage.clear();
    if(this.param.type=="admin")
    {
      console.log("dans admin")
      this.nav.navigate(['/admin/login'])
    }
    else{
      console.log("dans client")
      this.nav.navigate(['/login'])
    }

  }

  changePassWord(){
    this.loginResetData.email =this.param.email;
    if( this.loginResetData.old_password && this.loginResetData.new_password && this.loginResetData.new_password_confirm ){

      if(this.loginResetData.new_password == this.loginResetData.new_password_confirm )
      {
        if (this.loginResetData.old_password !=this.loginResetData.new_password)
        {
          if(this.param.type=="admin")
          {
            this.loader.startLoader("loader-02");
            this.changeAdminPass();
          }
          else
          {
            this.loader.startLoader("loader-02");
            this.changeCLientPass();
          }
        }
        else
        {
          this.toast.warning("Impossible d'utiliser votre ancien mot de passe")
        }
        //this.reclamationData.transaction_type_id =  parseInt(this.reclamationData.transaction_type_id)

      }
      else
      {
        this.toast.warning("La confirmation du mot de passe est incorrecte")
      }


    }else{
      this.toast.warning("Saisiisez correctement les champs svp")
    }
  }

  changeAdminPass(){
    this.api.changeAdminPass(this.loginResetData).then((res:any)=>{
      this.loader.stopLoader("loader-02");
      this.closeModal(res)
      this.toast.success(res.message)
    }).catch(err=>{
      this.loader.stopLoader("loader-02");
      //this.closeModal(err)
      this.toast.error(err.error.message)
    })
  }



  changeCLientPass(){

    this.api.changeClientPass(this.loginResetData).then((res:any)=>{
      this.loader.stopLoader("loader-02");
      this.closeModal(res)
      this.toast.success(res.message)
    }).catch(err=>{
      this.loader.stopLoader("loader-02");
      //this.closeModal(err)
      this.toast.error(err.error.message)
    })
  }





}

import { NgModule } from '@angular/core';
import { CustomCurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [
    CustomCurrencyPipe
  ],
  entryComponents:[
  ],
  imports: [

    ],
    providers: [
    ],
    exports: [CustomCurrencyPipe]
  })
export class CustomCurrencyPipeModule { }

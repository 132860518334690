import { Component, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'WeeCard';


  ngOnDestroy() {
    this.storage.clear();
    console.log('clear storage')
  }

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,

  ) { 
  
  }


  
 

}

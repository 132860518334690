import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AuthenticationService { 

   //baseUrl = "http://weecard_api_v3.local:9090";
    // baseUrl = "https://weecard-admin.totalenergies.com:90";
    baseUrl = "https://test-api.weecard.pro";
    baseUrlGetaway = "http://payment-sanbox.weecard.pro/api/";


  apiAdminUrl = this.baseUrl + "/api/admin";
  apiUrl = this.baseUrl + "/api";
  user: any;
  token: any;
  httpOptions: any;
  
  constructor(
    public http: HttpClient,
    public nav: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private toastr: ToastrService,
    public router: Router

  ) {

    this.token = sessionStorage.getItem("token");
    this.httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer" + this.token, "Access-Control-Allow-Origin": '*' })
    }; 

  }

  async TokenVerification(reponse) {
    console.log('dans admin TokenVerification')
    this.nav.events.subscribe((url:any) => console.log(url));
    console.log(  "this.router.url"+this.router.url);  //<---------- to get only path eg:"/signUp"
   
    if (reponse == 401) {
      this.toastr.warning("Votre session est expirée, veuillez vous connecter !", '', { timeOut: 5000 })

      this.storage.clear()

      if(this.nav.url.includes('admin'))
      {
        console.log('dans admin')
        this.nav.navigate(["/admin/login"]);
      }
      // else
      // {
      //   console.log('dans client')
      //   this.nav.navigate(["/client/login"]);
      // }
     
    }
    
  }

  checkSession() {
    console.log('dans checkSession')
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/checkSession", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // PARTIE ADMINISTRATEUR

  loginAdmin(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/login", data).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

   resetAdminPass(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    console.log("httpOptions");
    console.log(httpOptions);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/reset_password", data,httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }


  resetClientPass(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/reset_password", data, httpOptions).subscribe(
        (res: any) => {
           this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   logoutAdmin() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    console.log("httpOptions");
    console.log(httpOptions);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/logout", null,httpOptions).subscribe(
        (res: any) => {
          sessionStorage.removeItem("token");
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  adminActivation(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/admin_activation/" + remember_token, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  rememberTokenAdminCheck(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/forget_password_admin/" + remember_token, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  rememberTokenClientCheck(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client/forget_password_client/" + remember_token, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  clientActivation(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client_activation/" + remember_token, httpOptions).subscribe(
        (res: any) => {
            this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  changeAdminPass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/change_administrator_password", data).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeClientPass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/change_client_password", data).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeClientData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/change_client_data", data).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  searchHistoriqueClientBccBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/historique/list", data,httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  desableAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/desable_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  enableAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/enable_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAdmin() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_administrator", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getAdminById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_administrator/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listProfil() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_profil", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getPofilById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_profil/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAccess() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_access", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAccessMenu() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_access_menu", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getAccessById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_access/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  // deleteEntity(data) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({ Authorization: "Bearer "+sessionStorage.getItem("token") })
  //   };
  //   //console.log("token@@@@",sessionStorage.getItem("token"))
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.apiAdminUrl+"/delete_access", data, httpOptions).subscribe(
  //       (res: any) => {
  //         resolve(res);
  //       },
  //       err => {
  //         this.TokenVerification(err.status)
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  updateEntity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listEntity() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_entity", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addEntity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getEntityById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_entity/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }


  updateEntityType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity_type", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listEntityType() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_entity_type", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addEntityType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_entity_type", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getEntityTypeById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_entity_type/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }




  updateClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClientDiscountSettings() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/admin/client/getCLientDiscountSettings", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


   listClientCategory() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cat/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  setDiscountParam(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/UpdateClientDisounSetting", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClientCategoryById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cat/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  updateClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientType() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClientTypeById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_type/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /**Gestion des client  */
  createClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientAndSubmit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/create_submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/bulk_create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  checkTransactions(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/check_transaction", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  setMissedTransactionsAsExtracted(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
     
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/extactedMissiedtransactions/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getCurrentTransactionCheckByOr() {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
     
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getCurrentTransactionCheckByOr", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bulkUpdateRemise(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/bulk_update_remise", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientBulkAndSubmit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/bulk_create_submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  submitClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientValidDc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/sd_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientRejetDc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/remove_submision", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ClientValidEc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/compliance_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientRejetEc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/remove_sd_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientSearch(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/search/" + data.seach_mode + "/" + data.keyword, data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientSearchOnly(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/searchonly/" + data.seach_mode + "/" + data.keyword, data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientSoumission() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/list_clients_to_submit", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientValidCd() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/client_to_validate_by_cd", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClient() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientByPeriod(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/list/byperiod",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientValidEc() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/client_to_validate_by_compliance", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  setClientDiscount(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/setClientDiscount", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  disableClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/disable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  enableClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/enable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listCardFree() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////////////////////Enregistrer les cartes en masse ////////////////////
  saveCarteBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/create_bulk", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bulkCardImport(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/free_cards/import", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientCard() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //** GESTION DES CARTES */
  get_carte_vierge(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_carte_status() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_carte_type() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_cites() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/city/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_produit_list() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  creer_une_carte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  search_carte_user(search_mode, keyword) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/search/" + search_mode + "/" + keyword, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  // PARTIE CLIENT
  //////////////////////////liste des produits

  listProduit() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  list_for_br_creation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list_for_br_creation", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////// save Product

  // LISTE TYPE DE CARTE
  ListTypeCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // CREATION TYPE DE CARTE

  createTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  deleteTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // LISTE
  ListDesRegion() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/region/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  CreateRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  UpdateRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  DeleteRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Carte

  ListDesCarteState() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  CreateCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  UpdateCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchCardByNumber(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/" + card_number, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardOpposition(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/opposition", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardLeveOpossition(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/removeOpposition", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardRevalidation(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/revalidation", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  UpdateClientCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  DuplicateClientCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/duplicate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////// save Product

  saveProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////// Delete Product
  deleteProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////////Update Produit
  updateProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////// Liste city
  listCity() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/city/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////save city
  saveCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////////////// Upate city
  updateCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////delete city
  deleteCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////List stationsManager
  listStationManager() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_manager/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  

  freeCardCount() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/count", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////Save Station Manager
  saveStationManager(data) {
    console.log("iccicici");
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Update Station Manager
  updateStationManager(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete Station Manager
  deleteStationManager(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////// List stations
  listStation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_station", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete station
  deleteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Save Station
  saveStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_station", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Update Station
  updateStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////// List Des commandes articles
  listOrdrers() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/orders/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ////////////////////// Save Orders Articles
  saveOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////// update Orders Articles
  updateOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete Orders Articles
  deleteOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////Etat Livraison order article
  deliveryOrdersArticles(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/deliver", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////////// print PDF //////////////////////////////////////////////////////////////

  //////////// informations vivo
  getInfoVivo() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: "Bearer " + sessionStorage.getItem("token")
      })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/vivo", optionRequete).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////// informations Bank
  getInfoBank() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/bank", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////// informations Global
  getInfoGlobal() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token"), enctype: "multipart/form-data" })
    };
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: "Bearer " + sessionStorage.getItem("token")
      })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/global", optionRequete).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////////////////////////////////////////////////////////////////////////


  ////////////////////liste des pompiste

  ListPompistStation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_attendant/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

ListPompistStationByStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_attendant/by_station/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createPompisteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updatePompisteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ListStationPompiste() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_station", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  active_pompiste(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/active", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  desactive_pompiste(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/desactive", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  reset_pompiste_pin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/resset_pin", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  //======================= REPORTING +++++++++++++++++++++++++++++++++
  cardGlobalSettings() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/Carte", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  getRefUserIdAndIp() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/helpers/getRefUserIdAndIp", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  sapGlobalSettings() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/SAP", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchCardClientByClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/search/" + data.seach_mode + "/" + data.keyword, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /// ARTICLE
  ListDesArticles() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/articles/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //agence banque
  ListAgenceBanque() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_bank_agency", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_bank_agency", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  deleteAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Type transaction

  ListTypeTransaction() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  createTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  updateTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///// Banque
  ListDesBanques() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bank/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  deleteBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///// Mode Paiement

  ListPayMode() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/pay_mode/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  createPayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updatePayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deletePayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////// search BR
  searchBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/get/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////list Br a effacer
  listBordereau() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Payer Bordereau
  payerBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/pay", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //////////////Annuler Bordereau
  cancelBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //////////////Valider Bordereau
  validateBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/validate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //////////////Generer Paie PDF Bordereau
  bordereauGenerationPayPDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
         // this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////// details de bordereau
  detailsBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////// details de rechargements bloqués
  getBlockedChargeRequestLines() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getBlockedChargeRequestLines", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////// debloquer un chargement
  unlockRequestLine(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/unlockRequestLine",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////// changer le type d'un OR
  changeBrType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/changeBrType",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   /////////////////////////////// recuperer une ligne de chargement
   getChareRequestLineByCodeAndCardNumber(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getChareRequestLineByCodeAndCardNumber",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////// recuperer une ligne de chargement
  transfertRequestLine(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/transfertRequestLine",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///////listes des clients-utilisateurs
  ListClientsUsers() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_user/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ListClientsUsersByPeriodKeyword(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/list/byperiod_keyword",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

 

  ///////save clients-utilisateurs
  SaveClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////update clients-utilisateurs
  UpdateClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////delete clients-utilisateurs
  DeleteClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  //// Status bordereau
  ListMotifs() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/request_reason/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  createMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  updateMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // Liste statuts br
  listStatuBr() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/request_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Recherche bordereaux

  SearchNumClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClient/" + data.client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  SearchCodeBr(data) {
    let postdata;
    if(data.code)
    {
     postdata=data.code;
    }
    else
    {
     postdata=data;
    }
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/get/" + postdata, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/ByCreationDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDatePayment(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/ByPaimentDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  searchDateValidation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/ByValidationDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateStatus(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/search/" + data.state_code + "/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBySap(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClientSap/" + data.client_sap, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  detailSearchBR(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  getOrDiffFOrExport() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getAllDifferesChargeForExtraction" , httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getDuplicateLoadReport(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getCardLoadDuplicateReport" ,data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getOrDiffFOrExportByDate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getAllDifferesChargeForExtractionByDate/"+data.start+"/"+data.end , httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   getOrDiffFOrExportByBrCode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getAllDifferesChargeForExtractionByBrCode/"+data , httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  disableAllExportedCharges()
  {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/disableAllExportedCharges" , httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  disableSelectedExportedCharges(data)
  {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/disableSelectedExportedCharges",data , httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  exportDiffOrChargement() {

    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/OrExtractionExport", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  //BORDEREAU

  bordereauCreate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauUpdate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteOr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauByNumClient(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauPayInvoicePDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_invoice/" + br_code, httpOptions).subscribe(
        (res: any) => {
        //  this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

bordereauGenerationCreatePDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
         // this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cancelExpertBordereau(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
     // responseType: 'arraybuffer'
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/expert_cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  //BORDEREAU de carte


  // Liste statuts br
  listStatuBrCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/request_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
/////////////////////////////////////////// Liste des bordereaux Validés ///////////////////////
  listBorderauxValidate() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   listBorderauxValidateBybrcode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction/bybrcode/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listBorderauxValidateByDate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction/byDate/"+data.start+"/"+data.end, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listBorderauxValidateByClientNum(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction/bynumcli/"+data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  listBorderauxValidateBybccCode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction/bybcccode/"+data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////////////////// Liste des cartes  ///////////////////////
  listFreeCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/getRandomFreeCardList/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  // Recherche bordereaux

  SearchNumClientCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClient/" + data.client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  SearchCodeBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/get/" + data.code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/ByCreationDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  searchDatePaymentBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/ByPaimentDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  searchDateValidationBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/ByValidationDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateStatusBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/search/" + data.state_code + "/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBySapBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClientSap/" + data.client_sap, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  detailSearchBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //BORDEREAU

  bordereauCreateCarteNewClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/create_for_new_client", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauCreateCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauUpdateCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteOCC(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauByNumClientCarte(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauGenerationCreatePDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
         // this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cancelExpertBordereauCarte(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/expert_cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////// search BR
  searchBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/get/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////// search BR for webservice
  getChargeRequestDateForSoap(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/soap/getChargeRequestDateForSoap/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  getOrOccForPayment(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/payment/getOrOccForPayment/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////// set cards order via webservice
  setCardsOrders(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/soap/setOrCardsOrders", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

 ///////// set card order via webservice
 setCardOrder(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/soap/SetCardCreditEnhanced", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res);
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
    );
  });
}

setCardOrderOutofWeecard(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/soap/SetCardCreditEnhancedManuallyIntoTomcard", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res);
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
    );
  });
}



  //////////////list Br a effacer
  listBordereauCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Payer Bordereau
  payerBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/pay", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Annuler Bordereau
  cancelBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Valider Bordereau
  validateBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/validate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Generer Paie PDF Bordereau
  bordereauGenerationPayPDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
         // this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauGenerationRecusPDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_invoice/" + br_code, httpOptions).subscribe(
        (res: any) => {
        //  this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  /////////////////////////////// details de bordereau
  detailsBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  /////////////////////////////// RECHERCHE DE TRANSACTION
  search_transaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/search", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getDuplicateTransactions(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getDuplicateTransactions", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cancelDuplicateTransactions(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/soap/cancelDuplicateLoad", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listTransactionByClient(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getTransactionByClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  failedTomcardLoadBalance(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/getFailedTomcardCardLoadBalance", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  

  // PROFORMA
  listProforma() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listProformaByClient(num_client) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/byCLient/" + num_client, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createProforma(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateProforma(data) {
    let httpOptions : any= {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteProforma(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  proformaGenerationPayPDF(code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/topdf/" + code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  FactureGenerationPayPDF(code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/invoceTopdf/" + code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //GESTION TPE

  listTpe() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/tpe/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  assigneTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/assign", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  activeTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/active", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  desactiveTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/desactive", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  repordingGlobal(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/activity_recap", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ReclamationCreate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ReclamtionsList() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteReclamation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  UpdateReclamation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getReclamationByClient(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/getClaimsByClient/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getReclamationByStation(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/getClaimsByStation/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //setting

  getSettingType(type = null) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };

    let url: string = "";

    if (type) {
      url = "/global_settings/setting_by_type/" + type;
    } else {
      url = "/global_settings/getSettingsType";
    }
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + url, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateSetting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/global_settings/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  updateSettingBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/global_settings/bulk_update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  balanceStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/balanceStation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  GlobalebalanceStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/balanceStationAll", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  paymentReporting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentReporting", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //DASCHBOARD
  dashboardData() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/dashboard_boxes", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   dashboardDataByStation(station_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/dashboard_boxes_station/"+station_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartByMonth(month) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_sale_per_month/" + month, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartByMonthByStation(month,station_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_sale_per_month_by_station/" + month+"/"+station_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartHistogrammeByYear(year) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram/" + year, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  chartHistogrammeByYearByStation(year,staton_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram_by_station/" + year+"/"+staton_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  iniChartByTransactBySationPerMont(month,staton_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram_by_station_per_month/" + month+"/"+staton_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTransactionCAbyType" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  catransactionByTypeAndStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTransactionCAbyType_by_station" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentReportingByPayMode" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByProduct(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentByProducts" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  catransactionByProductAndStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentByProducts_by_station" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  clientRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getClientTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  stationRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getStationTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  getTop10CashTransactByStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTop10CashTransactByStation" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  top10CclientConsumeData(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getClientTop10Consumation" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  top10CardDebit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/top10CardDebit" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  top10CardCredit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/top10CardCredit" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bankRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getBankAgencyTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cartRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getCardTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  GenerateCratedCardBulk(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/generateCreatedCardSheet/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /** ============================================  Bornes =====================*/

  //////////////// List stations
  listBornes() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bornes/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete station
  deleteBornes(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Save Station
  saveBornes(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Update Station
  updateBornes(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   ///////////////////enable Station
   enableBornes(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/enable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////desable Station
  desableBornes(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/desable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  sapReporting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/sap/list", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  exportSapFile(data) {

    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/sap/exporte_sap", data, httpOptions).subscribe(
        (res: any) => {
        //  this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  finishOrExtraction(code_or) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/FinishExtractAvailableOrForLoadBalance/"+ code_or, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }  
  
  deleteOrLinesExtractionFile(code_or) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/deleteOrLinesExtractionFile/"+ code_or, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  DownloadOrExtraction( fileName): Observable<any> {
    return this.http.get(this.baseUrl + "/storage/"+ fileName, {
    responseType: "text"
  });
}


  exportOrLinesData(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/ExtractAvailableOrForLoadBalance/"+ data, httpOptions).subscribe(
        (res: any) => {
        //  this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // liste action borne
  listActionBorne() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bornes/list_borne_action", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // create instruction
  createInstructionBorne(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bornes/create_borne_instruction", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //liste des instruction
  listInstructionBorne() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bornes/get_borne_instruction", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //liste des transactions cash
  searchTransactionCash(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/search_transaction_cash",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //

  instructionAnnuler(borne_instruction_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bornes/cancel_borne_instruction/"+borne_instruction_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

//liste des transactions cash
  searchTransactionCollecte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/search_transaction_collecte",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //create message broadcast
  createMessageBroadcast(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/broadcast/save_broadcast_message", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //liste des broadcast
  listMessageBroadcast() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/broadcast_list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //maj broadcast
  updateMessageBroadcast(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/broadcast/update_broadcast", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //create message broadcast
  createTemplateMail(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/template/save_template", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //liste des broadcast
  listTemplateMail() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/template/template_list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //maj broadcast
  updateTemplateMail(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/template/update_template", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  messageNonLu() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/message_not_read", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  messageLu(message_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/save_read_message/"+message_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getLog() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/log/get_log", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listLog() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/log/get_log", httpOptions).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searcheLog(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/log/searchLog",data, httpOptions).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   adminPasswordForget(data) {
    /* let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    }; */
    //console.log("token@@@@",sessionStorage.getItem("token"))
    //, httpOptions
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/admin/password_forget", data).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   changeAdminPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/admin/change_forget_password_admin", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////client password forget
  clientPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client/password_forget", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
        );
      });
    }

    changeClientPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client/change_forget_password_client", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            this.setToken(res);
          } 
          resolve(res); 
        }
        ,
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
    );
    });
  }

  deleteBroadcast(message_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
    this.http.get(this.apiUrl + "/delete_broadcast/"+message_id, httpOptions).subscribe(
      (res: any) => {
        if(res.api_token && res.api_token != null){
          this.setToken(res)
        } 
        resolve(res); 
    },
    err => {
      this.TokenVerification(err.status)
      reject(err);
    }
  );
  });
  }
  // searchRecapPaiement(data) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  //   };
  //   //console.log("token@@@@",sessionStorage.getItem("token"))
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.apiUrl + "/br/recap_paiement", data, httpOptions).subscribe(
  //       (res: any) => {
  //         if(res.api_token && res.api_token != null){
  //           this.setToken(res);
  //         } 
  //         resolve(res); 
  //       }
  //       ,
  //       err => {
  //         this.TokenVerification(err.status)
  //         reject(err);
  //       }
  //   );
  //     });
  //   }
  searchRepositingBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/br_repositing/search", data, httpOptions).subscribe(
      (res: any) => {
         if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  searchgetPenddingLoad(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getPenddingLoad", data, httpOptions).subscribe(
        (res: any) => {
           if(res.api_token && res.api_token != null){
              this.setToken(res);
            }
            resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
        );
      });
    }  
    
    
   async getBlockedChareRequestLine() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getBlockedChareRequestLine", httpOptions).subscribe(
        (res: any) => {
           if(res.api_token && res.api_token != null){
              this.setToken(res);
            }
            resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
        );
      });
    }


   repositingBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/repositing", data, httpOptions).subscribe(
      (res: any) => {
        if(res.api_token && res.api_token != null){
            this.setToken(res);
          }
          resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

    
/*   searchRecapPaiement(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/recap_paiement", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          } 
          resolve(res); 
        }
        ,
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
    );
    });
  } */

  setToken(res){
    if("api_token" in res){
      if(res.api_token != null){
        sessionStorage.setItem("token", res.api_token);
        return ;
      }
      
    }
    if("data" in res){
      if("api_token" in res.data){
        if(res.data.api_token != null){
          sessionStorage.setItem("token", res.data.api_token);
          return ;
        }
      }
    }
  }
  checkTokenConfirmBCC(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/confirm_state/"+data['code']+"/"+data['code_validation'], httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  checkTokenConfirmBR(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/confirm_state/"+data['code']+"/"+data['code_validation'], httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

    searchRecapPaiement(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/recap_paiement", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          } 
          resolve(res); 
        }
        ,
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
    );
    });
  }

  detailProforma(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/details/" + data , httpOptions).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  searchExtractionOr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getAvailableOrForLoadBalance", data, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          } 
          resolve(res); 
        }
        ,
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
    );
    });
  }

  export_recap_paiement(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/export_recap_paiement",data , httpOptions).subscribe(
        (res: any) => {
        //  this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  getHistoriqueDetaille(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/getClientOrOccHistryDetailed", data,httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updatePhoneNumber(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/setCardPhoneNumber", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  publishBroadcast(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/broadcast/ChangeBroadCastStatus", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  messageListNotif() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/getAvailablesmessagesByPeriod", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getListOperator() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/epaymentProviders/activesProvilderList", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getFeesOperator(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrlGetaway + "epayment/getFees", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  sendRequstDataTogateWay(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request/sendRequstDataTogateWay", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  processPaymentWave(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrlGetaway + "epayment/processPayment", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getListProvider() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/epaymentProviders/list", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  getListFormula() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/epaymentProviders/getePaymentFormularList", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  enableProvider(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/epaymentProviders/active", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////desable Station
  desableProvider(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/epaymentProviders/desactive", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  checkStatusPay(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrlGetaway + "epayment/checkPaymentStatus", data , httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  getePaymentHistoriesByDate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/epayment/getePaymentHistoriesByDate", data , httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateProvider(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/epaymentProviders/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getListformule() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/epaymentProviders/getePaymentFormularList", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateFormuleFrais(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/epaymentProviders/setProvidersFormularAndRules", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  checkIntervalle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/epaymentProviders/getePaymentFormularRulesListByprovider/"+data , httpOptions).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
    
  }


  //recherche bordereau a valider
  searchBordereauAvalider(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/payment/getOrOccPaidListForValidation",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  //recherche bordereau a valider
  processValidationBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/payment/processOrOccValidation",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

    /**
   * Avoir les données de validation d'un birdereau(or/occ)
   * @param code 
   * @returns 
   */
    getValidationData(code) {
      let httpOptions = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }),
      };
      //console.log("token@@@@",sessionStorage.getItem("token"))
      return new Promise((resolve, reject) => {
        this.http
          .get(this.apiUrl + "/payment/getValidationData/" + code, httpOptions)
          .subscribe(
            (res: any) => {
              if(res.api_token && res.api_token != null){
                sessionStorage.setItem("token", res.api_token);
                //console.log(res.api_token)
              }
              resolve(res);
            },
            (err) => {
              this.TokenVerification(err.status);
              reject(err);
            }
          );
      });
    }

    //valider otp
  // validateOtp(data) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  //   };
  //   //console.log("token@@@@",sessionStorage.getItem("token"))
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.apiAdminUrl + "/login2fa",data, httpOptions).subscribe(
  //       (res: any) => {
  //         this.setToken(res);
  //         resolve(res);
  //       },
  //       err => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  
  //List reporting recap frais de service
  ListRecapFRaisDeServiceReport() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/service_fees/getServiceFeesReportList", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  //Telecharger un reporting recap frais de service
  DoawnloadRecapFRaisDeServiceReport(id) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/service_fees/downloadServiceFeesReport/"+id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //Supprimer un reporting recap frais de service
  DeleteRecapFRaisDeServiceReportFile(id) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
     
    };

    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/service_fees/deleteServiceFeesReport/"+id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

/**
 * Vérification d'un OTP 2fa après login
 * @param data données à soummetre à la verifitaction de l'OTP
 * @returns 
 */
  validateOtp(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/login2fa", data).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //refresh two factor
  refreshtwofactor(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/reset2fa", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }




}

import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription, interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/apiService';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { PaymentErrorComponent } from '../payment-error/payment-error.component';

@Component({
  selector: 'app-payment-qrcode',
  templateUrl: './payment-qrcode.component.html',
  styleUrls: ['./payment-qrcode.component.scss']
})
export class PaymentQrcodeComponent implements OnInit, OnDestroy  {

  public qrCodePayment: string = null;
  subscription !: Subscription;
  payData : any;
  myTimeout=null;
  constructor (
    public dialogRef: MatDialogRef<PaymentQrcodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public api: AuthenticationService,
    private loader: NgxUiLoaderService,
    public toast: ToastrService,
    private nav: Router,
    public dialog: MatDialog,
  ) {
    this.qrCodePayment = atob(this.param.data.checkout_launch_url);
  }

    ngOnInit() {
        // this.checkStatutPayment();
      let paramStatus={
        ref:this.param.data.ref,
        check_source:"weecard"
      }
      this.loader.startLoader('search-01');
      this.subscription = timer(0, 5000).pipe(
        switchMap(() => this.api.checkStatusPay(paramStatus))
      ).subscribe(res => {
        this.payData = res;
        console.log('res paydata' );
        console.log(this.payData );
        if(this.payData.data.payment_status == "succeeded" && this.payData.data.checkout_status == "complete")
        {
          console.log("res IN" );
        console.log(res );
            const dialogRef = this.dialog.open(PaymentSuccessComponent, {
              height:'500px',
              width: '18%',
               data:this.payData
        
            });
            this.ngOnDestroy();
            this.CloseDialog();

        } 
         if(this.payData.type== "checkout.session.payment_failed")
        {
            const dialogRef = this.dialog.open(PaymentErrorComponent, {
              height:'500px',
              width: '18%',
               data:this.payData
        
            });
            this.ngOnDestroy();
            this.CloseDialog();
        }
        
        
       });
      // if(this.payData.data.payment_status != "succeeded" && this.payData.data.checkout_status != "complete")
      //   {
      //       const dialogRef = this.dialog.open(PaymentErrorComponent, {
      //         height:'500px',
      //         width: '18%',
      //          data:this.payData
        
      //       });

      //   }
    //   this.myTimeout=setTimeout(() => {
    //     this.checkStatutPayment()
    //   }, 5000);
    }
  
  checkStatutPayment(){
    let paramStatus={
        ref:this.param.data.ref,
        check_source:"weecard"
      }
      this.loader.startLoader('search-01');
      this.subscription = timer(0, 5000).pipe(
        switchMap(() => this.api.checkStatusPay(paramStatus))
      ).subscribe(res => {
        this.payData = res;
        console.log('res paydata' );
        console.log(this.payData );
        if(this.payData.data.payment_status == "succeeded" && this.payData.data.checkout_status == "complete")
        {
          console.log("res IN" );
        console.log(res );
            const dialogRef = this.dialog.open(PaymentSuccessComponent, {
              height:'500px',
              width: '18%',
               data:this.payData
        
            });
            this.ngOnDestroy();
            this.CloseDialog();

        } 
      //  else if(this.payData.data.payment_status != "succeeded" && this.payData.data.checkout_status != "complete" && this.payData.data.payment_status != "processing")
       else if(this.payData.type== "checkout.session.payment_failed")
        {
            const dialogRef = this.dialog.open(PaymentErrorComponent, {
              height:'500px',
              width: '40%',
               data:this.payData
        
            });
            this.ngOnDestroy();
            this.CloseDialog();
        }else{

          this.checkStatutPayment();
        }
          });
  }

  
  stopTimeOut(){
    clearTimeout(this.myTimeout); 
  }

  CloseDialog(){
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
}

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { LogoutAdminGuard } from './guards/logout-admin.guard';
import { LogoutClientGuard } from './guards/logout-client.guard';
import { ClientAuthGuardGuard } from './guards/client-auth-guard.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: './pages.admin/pages.module#PagesModule',
    canActivate:[AdminAuthGuard]

  },
  {
    path: 'client',
    loadChildren: './pages.client/pages.module#PagesModule',
    canActivate:[ClientAuthGuardGuard]
  },
  {
    path: 'client/login',
    loadChildren: './login.client/login.module#LoginModule',
    canActivate:[LogoutClientGuard]
  },
  {
    path: 'admin/login',
    loadChildren: './login.admin/login.module#LoginModule',
    canActivate:[LogoutAdminGuard]
  },
  {
    path: '',
    loadChildren: './switch-page/switch-page.module#SwitchPageModule',

  }, 
  {
    path: 'login-reset',
    loadChildren: './login.reset/reset.module#ResetModule',

  },
  {
    path: 'activation/:source/:remember_token',
    loadChildren: './account-activation/account-activation.module#AccountActivationModule'
  },
  {
    path: 'password-forget/:source/:remember_token',
    loadChildren: './password-forget/password-forget.module#PasswordForgetModule'
  },
  {
    path: 'payment-success',
    loadChildren: './payment-success/payment-success.module#PaymentSuccessModule'
  },
  {
    path: 'payment-error',
    loadChildren: './payment-error/payment-error.module#PaymentErrorModule'
  },
  {
    path: 'authenticator',
    loadChildren: './authenticator/authenticator.module#AuthenticatorModule'
  },

  // {
  //   path: 'register',
  //   loadChildren: './register/register.module#RegisterModule'
  // },
  // {
  //   path: '404',
  //   loadChildren: './not-found/not-found.module#NotFoundModule'
  // },

  // { path: '**', redirectTo: ''},
  // { path: '**/**', redirectTo: ''},

  // {path:'', redirectTo:'admin/login', pathMatch:'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthenticationService } from 'src/app/services/apiService';

@Component({
  selector: 'app-confirm-epayment-modal',
  templateUrl: './confirm-epayment-modal.component.html',
  styleUrls: ['./confirm-epayment-modal.component.scss']
})
export class ConfirmEpaymentModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmEpaymentModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    public auth: AuthenticationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
  ) {
  }

  async ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close({ status: 0, message: "Annulation de l'opération !" });
  }


  valider(){
    
      this.dialogRef.close({ status: 1, message: "Confirmation de l'opération !" });
  }

}

import { ApplicationRef, Component, ComponentFactoryResolver, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthenticationService } from 'src/app/services/apiService';
import { PaymentModeDetailsModalComponent } from '../payment-mode-details-modal/payment-mode-details-modal.component';
import { PaymentQrcodeComponent } from '../payment-qrcode/payment-qrcode.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmEpaymentModalComponent } from 'src/app/pages.client/confirm-epayment-modal/confirm-epayment-modal.component';
import { DomPortalHost } from '@angular/cdk/portal';
import { JsonCompressionService } from 'src/app/utils/compressionDecompression';

@Component({
  selector: 'app-payment-mode',
  templateUrl: './payment-mode.component.html',
  styleUrls: ['./payment-mode.component.scss']
})
export class PaymentModeComponent implements OnInit {
 isShowErrorMessage = false;
 isShowWave = false;
 isShowOrange = false;
 isImageDisplay = true;
 //isShowModalView = true;
 //isViewIframe = false;
 listOperator:any;
 listOperatorData=[];
 getIdProvider:any;
 errroMessage="";

 feesOperatorWave: any = {};
 getInfoFees:any;
 getFees:any;
 getFormulaId:any;
 getFormulaValue:any;
 getRefBordereau:any;
 paymentProcessWave={
  provider_id:null,
  amount:null,
  resource_ref:null,
  resource_type:null,
  fee:null,
  formula_id:null,
  fee_formula_value:null,
  // payer_mobile:null,
  payed_from:null,
  userid:null
 };

 detailData=null;

 amountWaveBusness=null;
 requestRef="";
 userClientId;
//  amountWaveBusness=null;

 qrCodeWave: SafeResourceUrl | undefined;
  externalWindow: Window;
  constructor(
    public dialogRef: MatDialogRef<PaymentModeComponent>,
    private loader: NgxUiLoaderService,
    public sanitizer: DomSanitizer,
    public auth: AuthenticationService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    @Optional() @Inject(MAT_DIALOG_DATA) public paramClient: any,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public toast: ToastrService,
    //open link to pay
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private jsonCompressionService: JsonCompressionService
  ) { 
    console.log('this.feesOperatorWave maxime')
      console.log(this.param)
      console.log('this.param client maxime a voir')
      console.log(this.paramClient)
      this.detailData = this.param;
  }

  async ngOnInit() {
    let userData:any = await this.jsonCompressionService.decompress(this.storage.get("user"));
    this.userClientId=userData.id;
    this.listOperator = await this.auth.getListOperator();
         this.listOperatorData = this.listOperator.data
console.log('this.listOperatorData')
console.log(this.listOperatorData)
  }


  selectWave(item){
      if(item==1){
        this.loader.startLoader('search-01');
       this.isShowWave = true;
       this.isShowOrange = false;
       this.isImageDisplay = false;
      
         //this.feesOperatorWave.provider_id = this.listOperator.data[0].id,
         this.feesOperatorWave.provider_id = item
         this.getIdProvider=item;
         this.feesOperatorWave.amount = (this.param.state==0)?Math.floor(this.param.net_to_pay):Math.floor(this.param['montant_br']),
       
         console.log('this.feesOperatorWave')
         console.log(this.feesOperatorWave)
       this.auth.getFeesOperator(this.feesOperatorWave)
         .then((res: any) => {
           this.loader.stopLoader("search-01");
           console.log(res);
           this.getInfoFees = res.data
           this.getFees = res.data.fees_value
           this.getFormulaId = res.data.fees_foirmula_id
           this.getFormulaValue = res.data.fees_formula_value
            this.validateWave()
         })
         .catch(res => {
           this.loader.stopLoader("search-01");
           this.toast.error("Une erreur est survenue! Merci de réessayer svp");
           return;
           // this.dialogRef.close({ status: 0, message: res.error.message });
           console.log(res);
         });
         this.loader.stopLoader("search-01");
      }
      if(item==2){
        this.feesOperatorWave.provider_id = item
         this.getIdProvider=item;
         this.feesOperatorWave.amount = (this.param.state==0)?Math.floor(this.param.net_to_pay):Math.floor(this.param['montant_br']),
         this.loader.startLoader("search-01");
         console.log('this.feesOperatorWave business')
         console.log(this.feesOperatorWave)
       this.auth.getFeesOperator(this.feesOperatorWave)
         .then((res: any) => {
          //  this.loader.stopLoader("search-01");
           console.log('res business');
           console.log(res);
           this.getInfoFees = res.data
           this.getFees = res.data.fees_value
           this.getFormulaId = res.data.fees_foirmula_id
           this.getFormulaValue = res.data.fees_formula_value
           this.amountWaveBusness = this.feesOperatorWave.amount+this.getFees

                let RequestData={
                  request_code:(this.param['codeBordereau']!=undefined)?this.param['codeBordereau']:this.param['code'],
                  amount:(this.param['montant_br']!=undefined)?this.param['montant_br']:this.param['net_to_pay'],
                  provider_id:item,
                  resource_type:(this.param['type_resource']!=undefined)?this.param['type_resource']:this.param['request_type'], 
                  
                  fees: this.getFees,
                  formula_id:this.getFormulaId,
                  formular_value: this.getFormulaValue,
                }
               
                this.auth.sendRequstDataTogateWay(RequestData)
                .then((res: any) => {
                  console.log('res business');
                  console.log(res);
                  this.requestRef = res.data.ref
                  this.loader.stopLoader("search-01");
                  const dialogRef = this.dialog.open(ConfirmEpaymentModalComponent, {
                    width: '250px',
                     data: RequestData
                  });
                  this.dialogRef.close({ status: 1, message: "Confirmation de l'opération !" });
                  dialogRef.afterClosed().subscribe(async (result: any = { status: 0, message: null }) => {
                      console.log('The dialog was closed');
                      console.log(result);
                      if (result.status) {
                        this.validationPaymentWaveBusiness();
                      } 
                  });
                  
                })
                .catch(res => {
                //  this.loader.stopLoader("search-01");
                  this.toast.error(res.error.message);
                  this.loader.stopLoader("search-01");
                  return;
                  console.log(res);
                });

         })
         .catch(res => {
          //  this.loader.stopLoader("search-01");
           this.toast.error("Une erreur est survenue! Merci de réessayer svp");
           this.loader.stopLoader("search-01");
           return;
           console.log(res);
         });
        //  this.loader.stopLoader("search-01"); 

        


        
      }
      // else{
      //   this.toast.warning("Opérateur indisponible pour le moment. Réessayez plus tard...")
      //   return;
      // }
  }

  selectOrange(){
    this.isShowOrange = true;
    this.isShowWave = false;
    this.isImageDisplay = false;
  }
  
  annuler(){
    this.isImageDisplay = true;
    this.isShowOrange = false;
    this.isShowWave = false;
  }

  validateWave(){
    this.loader.startLoader('search-01');

    // if(!this.paymentProcessWave.payer_mobile)
    // {
    //   this.loader.stopLoader('search-01');
    //   this.toast.error("Veuillez saisir un numéro de téléphone valide svp");
    //   return;
    // }
    // this.qrCodeWave = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    //this.isViewIframe = true;
    //this.isShowModalView = false;
    this.loader.startLoader("loader-02");
    this.paymentProcessWave.provider_id = this.getIdProvider,
    this.paymentProcessWave.amount = (this.param.state==0)?Math.floor(this.param.net_to_pay):Math.floor(this.param['montant_br']),
    this.paymentProcessWave.resource_ref = (this.param.state==0)?this.param.code:this.param['codeBordereau'].toString(),
    this.paymentProcessWave.resource_type = (this.param.state==0)?this.param.request_type:this.param['type_resource'],
    this.paymentProcessWave.fee = this.getFees,
    this.paymentProcessWave.formula_id = this.getFormulaId,
    this.paymentProcessWave.fee_formula_value = this.getFormulaValue,
    //  this.paymentProcessWave.payer_mobile = "0709414469",//this.paymentProcessWave.payer_mobile,
    this.paymentProcessWave.payed_from = "weecard",
    this.paymentProcessWave.userid = this.userClientId,
    
    this.auth
      .processPaymentWave(this.paymentProcessWave)
      .then((res: any) => {
        this.loader.stopLoader("loader-02");
        console.log(res);
        this.CloseDialog(0);
        this.isShowErrorMessage=false;
          const dialogRef = this.dialog.open(PaymentQrcodeComponent, {
            width: '40%',
             height:'500px',
             data: res,
            //  disableClose: true
      
          })
         
      })
      .catch(res => {
        console.log("1:"+res.message);
       
        this.loader.stopLoader("loader-02");
        console.log("errroMessage:"+  this.errroMessage);
        this.errroMessage=res.message;
        this.isShowErrorMessage=true;
        this.isImageDisplay=false;
        this.isShowWave=false;
        this.isShowOrange=false;
        
        this.loader.stopLoader("loader-02");
       
        // console.log(res);
      });

      
        this.loader.stopLoader("search-01");
  }
  
  CloseDialog(state){
    this.dialogRef.close();
    if(state && state==1)
    {
      this.toast.info("Vous pouvez reprendre le paiement à partir de la liste des bordereaux OR/OCC.", undefined, {
        positionClass: 'toast-top-center'
   })
    }
  }

  onLoad(){
    this.loader.startLoader('search-01');
    this.loader.stopLoader("search-01");
  }

  openDialog(): void {
   
    const dialogRef = this.dialog.open(PaymentModeDetailsModalComponent, {
      width: '16%',
      data: {...this.detailData, ...this.getInfoFees},

    });
    dialogRef.afterClosed().subscribe(async (result:any = {status:0,message:null}) => {
      console.log('The dialog was closed');
      console.log(result);
      // if(result.status){
      //   this.toast.success(result.message);
      //   this.init();
      // }else{
      //   if(result.message) this.toast.warning(result.message);
      // }
    });
  }

  validationPaymentWaveBusiness(){
    // STEP 4: create an external window
    this.externalWindow =(this.param.state==0)? window.open('https://business.wave.com/b2b?country=ci&recipient_id=M_OMhlro4ry1z6&amount='+this.amountWaveBusness+'&reference='+this.requestRef, '', 'width=800,height=600,left=200,top=600'):window.open('https://business.wave.com/b2b?country=ci&recipient_id=M_OMhlro4ry1z6&amount='+this.amountWaveBusness+'&reference='+this.requestRef, '', 'width=800,height=600,left=200,top=600');

    // STEP 5: create a PortalHost with the body of the new window document    
    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
      );
    }
}

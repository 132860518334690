import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-payment-mode-details-modal',
  templateUrl: './payment-mode-details-modal.component.html',
  styleUrls: ['./payment-mode-details-modal.component.scss']
})
export class PaymentModeDetailsModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PaymentModeDetailsModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
  ) { 
    console.log(param)
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}


import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from './services/apiService';
import { AuthenticationClientService } from './services/apiServiceClient';
import { HttpClientModule } from '@angular/common/http';

import { StorageServiceModule } from "ngx-webstorage-service";

import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { LoginResetComponent } from './login.reset/login.reset.component';
import { LoginForgetComponent } from './login-forget/login-forget.component';
import { ExportService } from 'src/app/utils/export.service'
import { PaymentModeDetailsModalComponent } from './paiement/payment-mode-details-modal/payment-mode-details-modal.component';
import { PaymentModeComponent } from './paiement/payment-mode/payment-mode.component';
import { PaymentQrcodeComponent } from './paiement/payment-qrcode/payment-qrcode.component';
import { PaymentSuccessComponent } from './paiement/payment-success/payment-success.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CustomCurrencyPipeModule } from './pipes/currency.module';
import { PaymentErrorComponent } from './paiement/payment-error/payment-error.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';



@NgModule({
  declarations: [AppComponent, LoginResetComponent, LoginForgetComponent,
    PaymentModeComponent,
    PaymentModeDetailsModalComponent,
    PaymentQrcodeComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    AuthenticatorComponent],
  entryComponents:[LoginResetComponent,LoginForgetComponent,
    PaymentModeComponent,
    PaymentModeDetailsModalComponent,
    PaymentQrcodeComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    AuthenticatorComponent
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    StorageServiceModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    CustomCurrencyPipeModule,
    QRCodeModule
  ],
  providers: [AuthenticationService,AuthenticationClientService,ExportService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// json-compression.service.ts
import { Injectable } from '@angular/core';
import * as LZString from 'lz-string';

@Injectable({
  providedIn: 'root',
})
export class JsonCompressionService {
  compress(data: object): string {
    const jsonString = JSON.stringify(data);
    const compressedString = LZString.compressToUTF16(jsonString);
    return compressedString;
  }

  decompress(compressedString: string): string {
    const jsonString = LZString.decompressFromUTF16(compressedString);
    if (jsonString) {
      return JSON.parse(jsonString);
    } else {
      throw new Error('Failed to decompress data.');
    }
  }
}

import { Injectable, Inject , OnInit} from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from 'src/app/services/apiService';

@Injectable({
  providedIn: 'root'
})



export class AdminRigthService implements OnInit{

  routeData: any = {action_create: 0 ,action_delete: 0 , action_edit: 0 };
  menu : Array<any> = [] ;
  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private toastr: ToastrService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
   
  }

   init(){
    this.auth.checkSession();
    this.checkFirsCOnnection();
    this.routeData = {action_create: 0 ,action_delete: 0 , action_edit: 0 };
   this.menu =  this.storage.get("admin-menu")
   this.routeData = this.find() ;

    console.log('init admin right',this.menu) ;
    console.log(this.routeData) ;
  }

  isCanDel(){
    return (this.routeData.action_delete==1) ? true : false ;
  }

  isCanEdite(){
    return (this.routeData.action_edit==1) ? true : false ;
  }

  isCanCreate()
  {
    let resul=(this.routeData.action_create==1)?true : false;
    //console.log("qbcozdncn");
    //console.log(resul);
    return (this.routeData.action_create==1) ? true : false ;
  }

  find(){
    let routeData: Array<any> = [] ;
    routeData = this.menu.filter((a)=>{
      let bol:boolean = (a.route == this.router.url) ;
      if(!bol){
        let thmp: Array<any> = [] ;
        thmp = a.menu_child.filter((ch)=>{return ch.route == this.router.url}) ;
        if(thmp.length > 0) bol = true ;
      }
      return bol ;
    }) ;
    if(routeData.length == 0){
      this.blockAccess();
      return {action_create: 0 ,action_delete: 0 , action_edit: 0 } ;
    }
    let reponse:any ;

    if(routeData[0].menu_child.length > 0){
      reponse = routeData[0].menu_child.filter((ch)=>{return ch.route == this.router.url})[0]
    }else{
      reponse = routeData[0] ;
    }
    return reponse ;
  }

  blockAccess(){
    console.log('BlockAccess') ;
    this.toastr.error("vous n'êtes pas autorisé à accéder à cette page '" + this.router.url +"' !","Accès interdit !")
    this.router.navigate(['/admin/login']) ;
  }


  checkFirsCOnnection()
  {

   if(this.storage.get("admin-data") && (this.storage.get("admin-data").is_first_connection==0 || this.storage.get("admin-data").is_first_connection=='0'))
   {
    this.toastr.error("Vous devrez changer votre mot de passe" )
    
    this.deco() ;
   }

  }
  

  
  deco(){

    this.storage.clear()

    this.router.navigate(['/']);
    // if(this.type == "admin"){
    //   this.nav.navigate(['/admin/login']);
    // }else{
    //   this.nav.navigate(['/login']);
    // }

  }




  


}

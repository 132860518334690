import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySpace'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: any, args: any[]): string {
    if(value){
      return value.replace(/,/g, " ");
    }
    return '';
   }
}

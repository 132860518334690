import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root",
})
export class AuthenticationClientService {
   //baseUrl = "http://weecard_api_v3.local:9090";
    // baseUrl = "https://weecard-admin.totalenergies.com:90";
    baseUrl = "https://test-api.weecard.pro";
  
  apiClientUrl = this.baseUrl + "/api/client";
  apiUrl = this.baseUrl + "/api";
  user: any;
  token: any;
 /*  0; */
  httpOptions: any;

  constructor(
    public http: HttpClient,
    public nav: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private toastr: ToastrService
  ) {
    this.token = sessionStorage.getItem("token");
    this.httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer" + this.token }),
    };
  }

  TokenVerificationClient(reponse) {
    if (reponse == 401) {
      this.storage.clear();
      this.toastr.warning("Votre session CLIENT est expirée, veuillez vous connecter !");
      // this.nav.navigate(["/client/login"]);
    
      if(this.nav.url.includes('client'))
      {
        console.log('dans client')
        this.nav.navigate(["/client/login"]);
      }
      // if(this.nav.url.includes('admin'))
      // {
      //   console.log('dans admin')
      //   this.nav.navigate(["/admin/login"]);
      // }
      // else
      // {
      //   console.log('dans client')
      //   this.nav.navigate(["/client/login"]);
      // }
    }
  }

  // PARTIE CLIENT

  loginClient(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiClientUrl + "/login", data).subscribe(
        (res: any) => {
          sessionStorage.setItem("token", res.data.access_token);
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  

  dashboardData(num_client) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiUrl + "/dashboard/client/" + num_client, httpOptions)
        .subscribe(
          (res: any) => {
            if(res.api_token && res.api_token != null){
              sessionStorage.setItem("token", res.api_token);
              //console.log(res.api_token)
            }
            resolve(res);
          },
          (err) => {
            this.TokenVerificationClient(err.status);
            reject(err);
          }
        );
    });
  }

  messageNonLu() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/message_not_read", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerificationClient(err.status)
          reject(err);
        }
      );
    });
  }

  messageLu(message_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/save_read_message/"+message_id, httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerificationClient(err.status)
          reject(err);
        }
      );
    });
  }

  messageListNotif() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/broadcast/getAvailablesmessagesByPeriod", httpOptions).subscribe(
        (res: any) => {
          if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
          }
          resolve(res);
        },
        err => {
          this.TokenVerificationClient(err.status)
          reject(err);
        }
      );
    });
  }

}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthenticationService } from 'src/app/services/apiService';
import { AdminRigthService } from 'src/app/utils/admin-rigth.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(
    public api: AuthenticationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public toast: ToastrService,
    private loader: NgxUiLoaderService,
    public dialog: MatDialog,
    public right: AdminRigthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public param: any,
    public dialogRef: MatDialogRef<PaymentSuccessComponent>,
  ) {
    console.log("param reçu")
    console.log(param)
  }

  ngOnInit() {
  }
  
printRecuBr(){
  if(this.param.data.resource_type =="OR")
  {
    console.log('or')
      this.loader.startLoader("loader-01");
      this.api.bordereauPayInvoicePDF(this.param.data.or_occ).then((res: any) => {
        console.log(res);
        this.downLoadBR(res)
        this.CloseDialog();
        this.toast.success(res.message);
        this.loader.stopLoader("loader-01");
      }).catch(res => {
        console.log(res);
        this.loader.stopLoader("loader-01");
        this.toast.error(res.error.message);
      });
  }else if(this.param.data.resource_type =="OCC"){
    console.log('occ')
    this.loader.startLoader("loader-01");
    this.api.bordereauGenerationRecusPDFCarte(this.param.data.or_occ).then((res: any) => {
      console.log(res);
      this.downLoadBR(res)
      this.CloseDialog();
      this.toast.success(res.message);
      this.loader.stopLoader("loader-01");
    }).catch(res => {
      console.log(res);
      this.loader.stopLoader("loader-01");
      this.toast.error(res.error.message);
    });
  }else{
    this.toast.error("Une erreur survenue lors de la vérification duyu paiement. Merci de contacter le suppor")
    console.log('else')
    return;
  }
}

  
  downLoadBR(data: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      this.toast.warning('Erreur de génération du pdf. veuillez reésayer');
    }
  }

  CloseDialog(){
    this.dialogRef.close();
  }

}
